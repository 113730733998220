<template>
  <div class="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:flex-col sm:align-center">
      <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Notion blocks for time, habit and goal tracking</h1>
      <p class="mt-5 mb-5 text-xl text-gray-500 sm:text-center">Embed productivity blocks on any Notion page and Notion template.</p>
    </div>
    <div class="relative">
      <div class="videoWrapper shadow-lg rounded-md border-l-4 border-r-4 border-t-2 border-b-2 border-black">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/rI9PQpthdqY?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <div v-if="currentUser" class="bg-white">
    <div class="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:py-8 lg:px-8">
      <div class="px-6 py-6 bg-yellow-100 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16">
        <h2 class="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-3xl">
          Want to embed blocks from your dashboard that will sync and keep the data?
        </h2>
        <div class="">
          Visit your dashboard (Tracker), edit a block and click on the Embed tab. Or add a new block to your dashboard and switch the embed on. It's simple, and your data will be stored and later added to reports after using Fresh start in Kairo's dashboard. You will get access to all the block faces and colors.
        </div>
      </div>
    </div>
  </div>

  <BlockBuilder></BlockBuilder>


  <div class="bg-white overflow-hidden mt-0 mb-10">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <h3 class="text-xl font-extrabold text-gray-900 tracking-tight sm:text-xl text-center">
        How to embed blocks in Notion
      </h3>
      <p class="mt-3 text-lg text-gray-500 text-center">
        Copy the URL of Kairo block.<br />Go to your Notion page and add a new /embed block.<br />Insert the URL into the dialog and click Embed link button.
      </p>
      <img class="mx-auto relative shadow-md rounded-md mb-2 mt-4" src="@/assets/notion/kairoNotionHowTo.gif">
    </div>
  </div>

  <div class="bg-white overflow-hidden mt-0 mb-10 hidden">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <h3 class="sm:text-xl md:text-3xl font-extrabold text-gray-900 tracking-tight text-center">
        Are you a creator of Notion templates and OS? Earn with Kairo.
      </h3>
      <p class="mt-3 text-lg text-gray-500 text-center">
        Embed Kairo's blocks in your templates with a referrer. Then, for every user you refer to Kairo via your unique link in the block, you receive free credit on your account, and they receive a 10% discount for a whole year! Contact us at support@getkairo.com to learn more.
      </p>
    </div>
  </div>

  <div class="bg-white hidden">
    <div class="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8 text-center">
      <div class="pt-10 max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
        <router-link
          to="signup"
          class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Start trial
        </router-link>
        <p class="mt-3 text-sm text-gray-600">
          <template>Or <router-link to="tracker" class="underline">try the free version</router-link>. </template>No credit card required.
        </p>
      </div>
    </div>
  </div>

  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <a href="https://twitter.com/kairohq" target="_blank" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-left text-base text-gray-400">
          &copy; 2024 Sotak, Ltd. All rights reserved. Contact: support@getkairo.com<br />
          <router-link to="terms">Terms of Service</router-link> | <router-link to="privacy-policy">Privacy Policy</router-link> | <router-link to="acceptable-use-policy">Acceptable Use Policy</router-link>
        </p>
      </div>
    </div>
  </footer>

</template>

<script>
  import BlockBuilder from '@/components/BlockBuilder.vue';
  import { computed, reactive } from 'vue'
  import { useHead } from '@vueuse/head';
  import {mapGetters} from "vuex";

  export default {
    name: "Notion",
    components: {
      BlockBuilder
    },
    setup() {
      const imageMeta = require('@/assets/notion/kairoOGImage.png');
      const siteData = reactive({
        title: `Notion blocks for time, habit and goal tracking`,
        description: `Embed productivity blocks on any Notion page and Notion template.`,
        url: `https://getkairo.com/notion-blocks`,
        image: `https://getkairo.com` + imageMeta
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: `description`,
            content: computed(() => siteData.description),
          },
          {
            name: `title`,
            content: computed(() => siteData.title),
          },
          {property: `og:type`,content: 'website'},
          {property: `og:url`,content: siteData.url},
          {property: `og:title`,content: siteData.title},
          {property: `og:description`,content: siteData.description},
          {property: `og:image`,content: siteData.image},
          {property: `twitter:card`,content: 'summary_large_image'},
          {property: `twitter:url`,content: siteData.url},
          {property: `twitter:title`,content: siteData.title},
          {property: `twitter:description`,content: siteData.description},
          {property: `twitter:image`,content: siteData.image}
        ],
      })
    },
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
    },
    methods: {
      encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
      },
      handleAccessSubmit(e) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': e.target.getAttribute('name'),
            ...this.formAccessData,
          }),
        })
            .then(() => this.toast.success(`Thank you for your interest, we will be in touch.`))
            .catch(() => this.toast.error(`Something went wrong. Please try again. :(`))
      },
    }
  };
</script>

<style>
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
